import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Title from "../components/Title";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";
import { StructuredText } from "react-datocms";

const About = ({ data }) => {
  const { datoCmsAbout } = data;
  const { desc, image, info, para, stacks, title } = datoCmsAbout;
  const gatsbyImage = getImage(image.gatsbyImageData);

  return (
    <Layout>
      <SEO
        title="About"
        description="about Belle Carrie - front-end developer"
      />
      <section className="about-page">
        <div className="section-center about-center">
          <GatsbyImage
            image={gatsbyImage}
            alt={image.alt}
            width={300}
            className="about-img"
          />

          <article className="about-text">
            <Title titleText={title} />
            <p className="about-text-title">{info}</p>
            <p>{desc}</p>
            <StructuredText data={para} />
            <div className="about-stack">
              {stacks.map(item => {
                return <span key={item.id}>{item.stack}</span>;
              })}
            </div>
          </article>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    datoCmsAbout {
      title
      para {
        value
      }
      stacks {
        stack
        id
      }
      info
      desc
      image {
        alt
        width
        height
        url
        size
        format
        focalPoint {
          x
          y
        }
        gatsbyImageData(width: 300, height: 300)
      }
    }
  }
`;

export default About;
